import Image from 'next/image';
import React from 'react';

export default function Features() {
  const data = [
    {
      icon: './icons/idea.svg',
      title: 'Desarrollo Personal',
      description:
        'Identifico y trabajo contigo para alcanzar tus metas personales, fortalecer tu autoestima y fomentar una mentalidad positiva.',
    },
    {
      icon: './icons/diagram.svg',
      title: 'Desarrollo Profesional',
      description:
        'Te asisto en el desarrollo de habilidades de liderazgo, gestión eficiente del tiempo y avance en tu carrera profesional.',
    },
    {
      icon: './icons/network.svg',
      title: 'Desarrollo Empresarial',
      description:
        'Apoyo a emprendedores y líderes en el establecimiento de metas empresariales claras y en el desarrollo de equipos de alto rendimiento.',
    },
    {
      icon: './icons/yoga.svg',
      title: 'Salud y Bienestar',
      description:
        'Te ayudo a mejorar tu salud física y emocional, a manejar el estrés y a encontrar un equilibrio saludable entre trabajo y vida personal.',
    },
    {
      icon: './icons/couple.svg',
      title: 'Relaciones Personales',
      description:
        'Te apoyo en el desarrollo de relaciones interpersonales saludables, mejorando la comunicación y la resolución de conflictos.',
    },
    {
      icon: './icons/skill-development.svg',
      title: 'Desarrollo de Habilidades',
      description:
        'Te facilito el desarrollo de habilidades clave como liderazgo, comunicación y resolución de problemas.',
    },
  ];
  return (
    <section className="relative z-10 px-5 py-10 overflow-hidden bg-white md:py-20">
      <div className="w-full max-w-xl mx-auto md:max-w-5xl 2xl:max-w-7xl text-brand-main">
        <h2
          className="mb-4 text-2xl text-center md:mb-8 md:text-4xl payfair text-brand-secondary "
          data-aos="fade-up"
          data-aos-delay="200"
        >
          Áreas que podemos enfocarnos
        </h2>
        <p
          className="mx-auto text-sm text-center md:text-base max-w-prose"
          data-aos="fade-up"
          data-aos-delay="400"
        >
          Trabajar conmigo te brindará herramientas y estrategias personalizadas para enfrentar
          desafíos y lograr un crecimiento significativo en diversas áreas de tu vida. A
          continuación, exploramos los principales beneficios:
        </p>
        <div className="box-content grid grid-cols-2 gap-5 mt-10 md:gap-y-10 md:grid-cols-3">
          {data.map((item, index) => (
            <div
              data-aos="fade-up"
              key={item.title}
              data-aos-delay={200 * (index + 1)}
              className="flex flex-col items-center p-3 mx-auto transition duration-500 ease-in-out md:justify-center lg:p-10 md:hover:ring ring-brand-main md:hover:shadow-2xl md:hover:rounded-2xl border-brand-main md:aspect-square"
            >
              <div className="relative p-10 mb-5 rounded-full bg-brand-main">
                <div className="relative w-16 h-16 md:w-16 md:h-16 ">
                  <Image src={item.icon} fill className="object-contain" alt={item.title} />
                </div>
              </div>
              <h2 className="mb-2 text-sm font-semibold text-center sm:text-lg md:text-xl payfair text-brand-secondary">
                {item.title}
              </h2>
              <p className="mx-auto text-xs text-center md:text-sm max-w-prose">
                {item.description}
              </p>
            </div>
          ))}
        </div>

        <div className="grid items-center grid-cols-1 gap-10 p-10 mt-10 rounded-lg md:p-20 text-brand-secondary lg:grid-cols-2 bg-brand-brown/10">
          <div>
            <h2
              className="mb-4 text-2xl md:mb-8 md:text-3xl payfair "
              data-aos="fade-up"
              data-aos-delay="200"
            >
              Adaptable a tus necesidades
            </h2>
            <p className="text-sm md:text-base max-w-prose" data-aos="fade-up" data-aos-delay="400">
              Estos son solo algunos de los puntos en los que podemos trabajar juntos. Dependiendo
              de tus necesidades específicas, podemos adaptar y explorar otros aspectos para
              asegurar tu desarrollo personal y profesional.
            </p>
          </div>
          <div>
            <h2
              className="mb-4 text-2xl md:mb-8 md:text-3xl payfair "
              data-aos="fade-up"
              data-aos-delay="200"
            >
              Potencia tu crecimiento personal y profesional
            </h2>
            <p className="text-sm md:text-base max-w-prose" data-aos="fade-up" data-aos-delay="400">
              El coaching y la psicología son herramientas poderosas que te ayudarán a superar
              obstáculos y a maximizar tu potencial. Utilizo técnicas basadas en la evidencia, como
              la terapia cognitivo-conductual y apoyo emocional, para ayudarte a avanzar hacia una
              vida más plena y satisfactoria.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
